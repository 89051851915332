import '../styles/globals.css';
import '../styles/header.scss';
import '../components/Slider.scss';
import '../components/TopInfluencers/Landing.scss';
import '../components/brands/brands.scss';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { AccountsProvider } from 'providers/AccountsProvider';
import type { AppProps } from 'next/app';
import ErrorBoundary from '@components/ErrorBoundary';
import { ApolloProvider } from '@apollo/client';
import client from '../apollo-client';
import ConfigProvider from 'antd/lib/config-provider';
import '../styles/antd.override.scss';
import 'swiper/swiper-bundle.min.css';
import Head from 'next/head';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" />
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.1.1/css/all.css" />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
      <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
      <Head>
        <meta property="og:site_name" content="Viralocity" />
      </Head>
      <ApolloProvider client={client}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#7bc8c0',
              colorPrimaryBorder: '#7bc8c0',
              fontFamily: 'Gilroy-Light',
              fontSize: 16,
            },
          }}
        >
          <ErrorBoundary>
            <AccountsProvider>
              <Component {...pageProps} />
            </AccountsProvider>
          </ErrorBoundary>
        </ConfigProvider>
      </ApolloProvider>
    </>
  );
}

export default MyApp;
