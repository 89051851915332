const routes = {
  HOME: '/',
  SIGNUP: '/signup',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  ALL_LISTINGS: '/listing',
  getListingDetails: (slug: string) => `/listing/${slug}`,
  getUserProfile: (id: string) => `/user/${id}`,

  ONBOARDING: '/onboarding',
  ONBOARDING_SOCIAL_CONNECTIONS: '/onboarding/social-connections',
  ONBOARDING_SUCCESS: '/onboarding/success',

  ERROR: '/error',
  SELLER_PROFILE_V2: (id: string) => `/user/${id}`,
  SELLER_PROFILE: '/user/',

  MYHUB_DASHBOARD: '/dashboard',
  CREATOR_HUB: '/dashboard/creator-hub',
  SELLER_HUB_DASHBOARD: '/dashboard/seller',
  CREATE_LISTING: '/dashboard/creator-hub/new-listing',
  ORDERS: '/dashboard/campaigns',
  MESSAGES: '/dashboard/messages',
  getEditListing: (listingId: string) => `/dashboard/creator-hub/edit/${listingId}`,
  DRAFT_LISTING: '/dashboard/creator-hub/drafts',
  SETTINGS_PERSONAL_INFO: '/dashboard/settings/personal-info',
  SETTINGS_PAYMENT: '/dashboard/settings/payments',
  SETTINGS_PREFERENCE: '/dashboard/settings/preference',

  BLOG: '/blog/',

  PRIVACY: '/static/privacy-policy',
  COOKIE_POLICY: '/static/cookie-policy',
  COMMUNITY_GUIDELINES: '/static/community-guidelines',
  TERMS_AND_CONDITIONS: '/static/terms-and-conditions',
};

export default routes;
