export const getRedirectURLs = () => {
  const isLocalhost =
    typeof window !== 'undefined' &&
    Boolean(
      window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
    );

  if (isLocalhost) {
    //http://localhost:6006/iframe.html?id=pages-home--component&viewMode=story
    return {
      signin: 'http://localhost:3000/dashboard',
      signOut: 'http://localhost:3000',
    };
  }

  const isDev = typeof window !== 'undefined' && Boolean(window.location.hostname.includes('dev.viralocity.io'));

  if (isDev) {
    return {
      signin: 'https://dev.viralocity.io/dashboard',
      signOut: 'https://dev.viralocity.io',
    };
  }

  const isQa = typeof window !== 'undefined' && Boolean(window.location.hostname.includes('qa.viralocity.io'));

  if (isQa) {
    return {
      signin: 'https://qa.viralocity.io/dashboard',
      signOut: 'https://qa.viralocity.io',
    };
  }

  return {
    signin: 'https://viralocity.io/dashboard',
    signOut: 'https://viralocity.io',
  };
};
