import { ApolloClient, createHttpLink, ApolloLink, InMemoryCache } from '@apollo/client';
import { Auth } from '@aws-amplify/auth';
import { setContext } from '@apollo/client/link/context';

const PUBLIC_ENDPOINT = process.env.NEXT_PUBLIC_API_PUBLIC_ENDPOINT;

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_API_PRIVATE_ENDPOINT
});

const authTokenMiddleware = setContext(async (_, { headers }) => {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

export const privateClient = new ApolloClient({
  link: ApolloLink.from([
    authTokenMiddleware,
    httpLink
  ]),
  cache: new InMemoryCache(),
  ssrMode: typeof window === 'undefined'
});

const client = new ApolloClient({
  uri: PUBLIC_ENDPOINT,
  cache: new InMemoryCache(),
  ssrMode: typeof window === 'undefined',
});

export async function fetchAPI(query, { variables } = {}) {
  const res = await fetch(PUBLIC_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  })

  const json = await res.json()
  if (json.errors) {
    throw new Error('Failed to fetch API')
  }
  return json.data
}

export default client;
