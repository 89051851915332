import {gql} from "@apollo/client";

export enum UserType {
  INFLUENCER = 'influencer',
  BRAND = 'brand'
}

export type BrandInfo = {
  isBrandOnboarded: boolean;
  titleRole: string;
  companyName: string;
  websiteUrl: string;
  specializations: string[];
  phoneNumber: string;
  targetGoals: string;
}

export type InfluencerInfo = {
  isInfluencerOnboarded: boolean;
  creatorName: string;
  location: string;
  bio: string;
  dateOfBirth: string;
  gender: string;
  contentType: string[];
  specializations: string[];
}

export const GET_ACCOUNT_SUMMARY_QUERY = gql`
  query GetMyAccountSummary {
    GetMyAccountSummary {
      accountId
      accountInfo {
          firstName
          lastName
          email
          phoneNumber
          type
          image
          gender
          dateOfBirth
          seller
      }
      influencerInfo {
        isInfluencerOnboarded
        creatorName
        bio
        location
        dateOfBirth
        gender
        contentType
        specializations
      }
      brandsInfo {
        isBrandOnboarded
        titleRole
        companyName
        websiteUrl
        specializations
        phoneNumber
        targetGoals
      }
    }
  }
`;

export const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateAccount($input: UpdateAccountInput){  
    UpdateAccount(input: $input) {
        accountId
    }
  }
`;

export interface GetMyAccountSummary {
  accountId: string;
  accountInfo: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    type: UserType;
    image: string;
    gender: string;
    dateOfBirth: string;
    seller: boolean;
  };
  influencerInfo: InfluencerInfo;
  brandsInfo: BrandInfo;
}
