import React, { ErrorInfo } from 'react';
import routes from 'routes';
import { NextRouter, withRouter } from 'next/router';

interface WithRouterProps {
  router: NextRouter;
  children: React.ReactNode
}

class ErrorBoundary extends React.Component<WithRouterProps> {
    constructor(props: WithRouterProps) {
      super(props)
  
      // Define a state variable to track whether is an error or not
      this.state = { hasError: false }
    }
    static getDerivedStateFromError() {
      // Update state so the next render will show the fallback UI
  
      return { hasError: true }
    }

    async componentDidCatch(errors: Error, errorInfo: ErrorInfo) {
      // You can use your own error logging service here
      console.log({ errors, errorInfo })
      await this.props.router.push(routes.ERROR);
      
      this.setState({
        hasError: false,
      });
    }

    render() {
      // Check if the error is thrown
      //@ts-ignore
      if (this.state.hasError) {
        return true;
      }
  
      // Return children components in case of no error
  
      return this.props.children
    }
  }
  
export default withRouter(ErrorBoundary);
