import React, { useEffect, useState } from 'react'
import { Auth, CognitoUser, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import awsconfig from '../aws-exports';
import Amplify from '@aws-amplify/core';
import { getRedirectURLs } from '@utils/amplify.util';
import { Hub } from "aws-amplify";
import Router, { useRouter } from 'next/router';
import routes from 'routes';

const { signOut, signin } = getRedirectURLs();

Amplify.configure({
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    redirectSignIn: signin,
    redirectSignOut: signOut,
  },
  ssr: true
});

interface userDetails {
  name?: string;
  email?: string;
  pic?: string;
  token: string;
  userId: string;
  sub: string;
}

export const redirectAfterLogin = (token?: string) => {
  let redirectInfo: any = localStorage.getItem('redirect');
  
  if (redirectInfo) {
    redirectInfo = JSON.parse(redirectInfo)

    const redirectURL = new URL(redirectInfo?.redirect)

    if (redirectInfo?.withToken && token) {
      redirectURL.searchParams.append('token', token)
    }
    localStorage.removeItem('redirect');
    setTimeout(()=> window.location.replace(redirectURL), 300);
  }
}

const useAuth = () => {
  const [user, setUser] = useState<userDetails | null>(null)
  const [loading, setLoading] = useState<Boolean>(true);
  const router = useRouter();

  useEffect(() => {
    const checkSession = async () => {
      try {
        const currentUser: CognitoUser = await Auth.currentAuthenticatedUser();

        setUser({
          name: currentUser?.getSignInUserSession()?.getIdToken()?.payload.email,
          email: currentUser?.getSignInUserSession()?.getIdToken()?.payload.email,
          token: currentUser?.getSignInUserSession()?.getIdToken().getJwtToken() || '',
          pic: '',
          userId: currentUser?.getSignInUserSession()?.getIdToken()?.payload['cognito:username'],//session.getIdToken().payload?.identities[0]?.userId,
          sub: 'account_' + currentUser?.getSignInUserSession()?.getIdToken()?.payload.sub
        });

        redirectAfterLogin(currentUser?.getSignInUserSession()?.getIdToken().getJwtToken());

        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }

    //Redirect to dashboard page after signin
    Hub.listen("auth", (data) => {
      if (data?.payload?.event === "signIn") {
        router.push(routes.MYHUB_DASHBOARD);
      }
    });

    checkSession();
  }, []);

  const signIn = (email: string, password: string) => {
    return Auth.signIn(email, password);
  }

  const signUp = (name: string, email: string, password: string) => {
    return Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        given_name: name
      },
    });
  }

  const forgetPassword = (email: string) => {
    return Auth.forgotPassword(email);
  }

  const forgetPasswordSubmit = (email: string, code: string, newPassword: string) => {
    return Auth.forgotPasswordSubmit(email, code, newPassword);
  }
  //console.log(user?.token);

  return {
    user,
    isLoggedIn: !!user,
    loading,
    signUp,
    signIn,
    signInWithGoogle: () => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google, customState: `?next=${window.location.pathname}` }),
    signout: () => Auth.signOut(),
    forgetPassword,
    forgetPasswordSubmit,
  }
}

export default useAuth;
