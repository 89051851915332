import React, { createContext, useContext, useEffect, useState } from 'react';
import useAuth from '@hooks/useAuth';
import { GetMyAccountSummary, GET_ACCOUNT_SUMMARY_QUERY, UserType } from 'queries/accounts.query';
import useAuthQuery from '@hooks/useAuthQuery';
import { useRouter } from 'next/router';
import routes from 'routes';

export type AccountInfo = {
    firstName: string;
    lastName: string;
    image: string | null;
    dateOfBirth: string;
    gender: string;
    type?: UserType | null;
    email: string;
    phoneNumber: string;
    accountId: string;
}

export interface AccountInfoState extends AccountInfo {
    loading: boolean;
    refetchProfile: () => void 
}

const initialState: AccountInfoState = {
    loading: true,
    firstName: '',
    lastName: '',
    image: null,
    dateOfBirth: '',
    gender: '',
    type: null,
    email: '',
    phoneNumber: '',
    accountId: '',
    refetchProfile: () => undefined
};

export const AccountsContext = createContext<AccountInfoState | null>(initialState);

const { Provider } = AccountsContext;

export const useAccount = (): AccountInfoState => {
    return useContext(AccountsContext)!;
};

export const AccountsProvider = (props: any) => {
    const { isLoggedIn, user } = useAuth();
    const router = useRouter();
    const {data: ProfileData, loading, refetch} = useAuthQuery<{GetMyAccountSummary: GetMyAccountSummary}>(GET_ACCOUNT_SUMMARY_QUERY, { 
        variables: { accountId: user?.userId },
        skip: !isLoggedIn || !user?.userId 
    });

    const [accountInfo, setAccountInfo] = useState<AccountInfoState>({...initialState, refetchProfile: refetch});

    useEffect(() => {
        if (ProfileData) {

            setAccountInfo({
                ...accountInfo,
                type: ProfileData?.GetMyAccountSummary?.accountInfo?.type || null,
                firstName: ProfileData?.GetMyAccountSummary?.accountInfo?.firstName,
                lastName: ProfileData?.GetMyAccountSummary?.accountInfo?.lastName,
                phoneNumber: ProfileData?.GetMyAccountSummary?.accountInfo?.phoneNumber,
                email: ProfileData?.GetMyAccountSummary?.accountInfo?.email,
                image: ProfileData?.GetMyAccountSummary?.accountInfo?.image,
                dateOfBirth: ProfileData?.GetMyAccountSummary?.accountInfo?.dateOfBirth,
                gender: ProfileData?.GetMyAccountSummary?.accountInfo?.gender,
                accountId: ProfileData?.GetMyAccountSummary?.accountId
            });

            //Routing first time users to onboarding screen 
            // if((!ProfileData?.GetMyAccountSummary?.brandsInfo.isBrandOnboarded && !ProfileData?.GetMyAccountSummary?.influencerInfo.isInfluencerOnboarded) && router.pathname !== routes.ONBOARDING){
            //     router.push(routes.ONBOARDING)
            // }
            //TODO: Update the profile_pic from local API or use the google profile pic
        }
    }, [ProfileData]);

    return <Provider value={{...accountInfo, loading }}>{props.children}</Provider>;
};
