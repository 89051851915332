import { OperationVariables, QueryHookOptions, TypedDocumentNode, useQuery } from '@apollo/client';
import { privateClient } from 'apollo-client';

export default function useAuthQuery<
  TData = any,
>(query: TypedDocumentNode, options: QueryHookOptions<TData> = {}) {
  return useQuery<TData>(query, {
        ...options,
        //skip: (loading as (boolean | undefined)) || options.skip,
        client: privateClient
    });
}
